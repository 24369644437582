.Popup {
  position: sticky;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: 10;
  padding: 48px clamp(16px, calc(36vw/7.68), 80px) clamp(16px, calc(36vw/7.68), 48px);
  background: var(--primary-100);
  border: 1px solid var(--primary-500);
  border-radius: 4px;
  max-height: 80vh;
  overflow-y: auto;

  .closeButton {
    position: absolute;
    top: 0;
    right: 0;
    padding: 16px;

    &:focus-visible {
      outline-offset: -2px;
    }
  }

  @media (max-width: 640px) {
    left: 16px;
    right: 16px;
  }

  >h2 {
    margin-bottom: clamp(8px, calc(12vw/7.68), 20px);
  }

  >p {
    margin-bottom: clamp(20px, calc(28vw/7.68), 28px);
  }

  .items {
    display: grid;
    gap: clamp(12px, calc(24vw/7.68), 24px);

    .item {
      display: grid;
      gap: clamp(20px, calc(24vw/7.68), 24px);
      grid-template-columns: auto 1fr;

      .link {
        overflow: hidden;
        border-radius: 4px;

        &:hover {
          img {
            transform: scale(1.05);
          }
        }

        >img {
          width: 293px;
          height: auto;
          aspect-ratio: 1.38/1;
          object-fit: cover;
          transition: transform 0.3s var(--easing);
        }
      }

      .content {
        display: grid;

        .textContent {
          gap: 8px;
          display: grid;
          align-self: flex-start;

          .rating {
            display: flex;
            gap: 4px;
            margin-bottom: 4px;

            span {
              font-size: calc(14rem/16);
              color: var(--primary-800, #53423c);

              b {
                font-weight: 300;
                font-size: calc(32rem/16);
                font-family: var(--font-city-streetwear);
              }
            }
          }
        }

        >button {
          align-self: flex-end;
        }
      }

      @media(max-width: 713px) {
        grid-template-columns: 1fr;

        .link {
          >img {
            width: 100%;
            height: auto;
            aspect-ratio: 1.38/1;
            border-radius: 4px;
            object-fit: cover;
          }
        }

        .content {
          justify-items: center;
          text-align: center;

          .rating {
            justify-self: center;
          }

          >button {
            margin-top: 20px;
          }
        }
      }
    }
  }
}