.StanVouchera {
  width: 100%;
  margin: 0 auto;
  text-align: center;

  > h1 {
    margin-bottom: 36px;
  }

  form {
    max-width: 380px;
    margin: 48px auto 0 auto;
  }

  form > button {
    margin: 36px auto 0 auto;
  }
}
