.ListingFiles {
  margin-top: clamp(96px, calc(96vw / 7.68), 144px);
  display: grid;
  gap: clamp(48px, calc(80vw / 7.68), 80px);

  h2 {
    font-size: clamp(calc(18rem / 16), calc(28vw / 7.68), calc(28rem / 16));
  }

  > div {
    display: grid;
    grid-template-columns: 277fr 587fr;
    gap: clamp(16px, calc(32vw / 7.68), 32px);

    @media (max-width: 640px) {
      grid-template-columns: 1fr;
    }

    ul {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: clamp(16px, calc(32vw / 7.68), 32px);

      @media (max-width: 1240px) {
        grid-template-columns: 1fr 1fr;
      }

      li {
        list-style: none;
      }
    }
  }
}
